import React from "react"
import { Layout } from "../components/Layout"
import {
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner
} from "../commonComponents"
import {
  IntegrationDarkSection
} from "../integrationsUtils"
import { Helmet } from "react-helmet"

export default function SaaSSupportAI() {
  return (
    <Layout
      keywords="saas ai agent assist, saas agent assist bot, saas customer support, customer support for saas"
      title="Best AI Agent Assist and Chatbot for SaaS industry | IrisAgent"
      description="Enhance SaaS software's customer support with our powerful AI agent-assist chatbot solution, powered by GPT."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/saas-support-ai/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span style={{ color: "#EF5DA8", fontWeight: 600 }}>Prevent churn</span> and scale up customer support operations for <span style={{ color: "#EF5DA8", fontWeight: 600 }}>SaaS</span>
            </h1>
            <div className="description-support-new-design ">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "20px" }}> Automate 60%+ support interactions
                </li>
              <li style={{ paddingLeft: "20px" }}>
               Automate triaging for complex cases
                </li>
                <li style={{ paddingLeft: "20px" }}>
               Proactive alerting for high value <br/>customers to improve CSAT
                </li>
            </ul>
            </div>

            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="saas-ai-background"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-right"
        style={{ marginBottom: "5%", marginLeft: "4%", marginTop: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#7879F1"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/img/homeIlustrationAA1.webp"
                style={{
                  width: "88%",
                  padding: "10%",
                }}
                alt="about section"
              />
            </div>
            <SectionDivider color="#7879F1"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/homeIlustrationAA1.webp"
                    alt="about section"
                    style={{ width: "88%", paddingBottom: "10%" }}
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  Enable self-service and automate triaging for faster resolution
                 </h2>
                  <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                  <span className="web-view-new-design-display">
                  <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    IrisGPT GenAI bot using LLMs efficiently responds to 60%+ queries via self-service with accurate, human-like responses.
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Automate workflows with AI driven auto-tagging, routing and prioritization. No more inaccurate manual tagging! Helps route and resolve tickets faster.
                    </li>
                  </ul>
                  </span>

                  <span className="mobile-view-new-design-display">
                  <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    IrisGPT GenAI bot using LLMs efficiently responds to 60%+ queries via self-service with accurate, human-like responses.
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Automate workflows with AI driven auto-tagging, routing and prioritization. No <br/>more inaccurate manual tagging! Helps route and resolve tickets faster.
                    </li>
                  </ul>
                  </span>





                  </div>
                <div style={{ display: "flex" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= About Similar Cases Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "5%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#EF5DA8"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/homeIlustration5.webp"
                    style={{
                      width: "94%",
                      paddingBottom: "10%",
                    }}
                    alt="about section"
                  />
                </div>
                <div
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <h2
                    className="section-title-new-design auto-detect-title"
                    style={{ marginBottom: "5%" }}
                  >
                    <span>
                      Empower agents and Prevent escalations with proactive monitoring
                      <br />
                    </span>
                    </h2>
                    <div className="generic-text"
                    style={{ marginBottom: "5%" }}
                    >
                    <span className="web-view-new-design-display">
                    <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}>
                    Give agents real-time insights from knowledge bases, historical ticketing data, and DevOps and engineering systems
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Discover at-risk saas customer support tickets with automated revenue and customer sentiment impact analysis
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Get real-time alerts about trending product and customer experience issues
                    </li>
                    </ul>
                    </span>

                    <span className="mobile-view-new-design-display">
                    <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}>
                    Give agents real-time insights from knowledge bases, historical ticketing data, and DevOps and engineering systems
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Discover at-risk saas customer support tickets with automated revenue and customer sentiment impact analysis
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Get real-time alerts about trending <br/>product and customer experience issues
                    </li>
                    </ul>

                    </span>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                  <GetStartedButton />
                </div>
              </div>
            </div>
            <SectionDivider color="#EF5DA8" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-start",
              }}
            >
              <img
                src="/img/homeIlustration5.webp"
                style={{
                  width: "90%",
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Similar Cases Section --> */}


      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}
            <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
                  <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is SaaS customer support? Provide examples and best practices."
                  answer="SaaS customer support for saas as (Software as a Service) customer support refers to the provision of customer service and assistance for users of SaaS products or services. It involves addressing user inquiries, resolving issues, and providing guidance on using the SaaS platform effectively. Examples of SaaS customer support include live chat support, email support, knowledge base articles, video tutorials, and self-service portals. Best practices for SaaS customer support include offering prompt responses, personalized assistance, proactive communication, continuous training for support agents, and leveraging automation tools to streamline processes and enhance user experience."
                />
                <Faq
                  id="faq2"
                  question="Why is customer support important for SaaS companies?"
                  answer="Customer support is crucial for SaaS companies because it directly impacts customer satisfaction, retention, and overall business success. SaaS products often require ongoing assistance, support tools, and guidance for users to maximize their value and ensure a positive experience. Effective customer support builds trust, fosters loyalty, and encourages referrals, ultimately driving revenue growth and market differentiation in the competitive SaaS landscape."
                />
                <Faq
                  id="faq3"
                  question="What metrics to include in your SaaS customer support strategy?"
                  answer="When developing a SaaS customer support strategy, it is essential to track key metrics to assess the performance and effectiveness of the customer support team. Some important metrics to include are: First response time, Resolution time, Customer satisfaction (CSAT), Net Promoter Score, Ticket volume, and Customer churn rate."
                />
                <Faq
                  id="faq4"
                  question="How does IrisAgent AI automate ticket tagging, chatbot answers, and sentiment analysis in Intercom?"
                  answer="IrisAgent automatically identifies tags specific to your domain or industry and applies them to new tickets without agent intervention. Our AI also analyzes Intercom chats for language cues that reveal the sentiment. IrisGPT with its webhook integration with Intercom chatbot finds accurate answers to customer queries by training on ticket and knowledge base data."
                />
                <Faq
                  id="faq5"
                  question="What are the critical components of a great SaaS support strategy?"
                  answer="The critical components of a great SaaS support strategy include proactive communication, personalized assistance, and efficient issue resolution. Proactive communication involves keeping customers informed about product updates, service disruptions, and upcoming features to manage expectations and build trust. Personalized assistance entails understanding each customer's unique needs and providing tailored solutions and recommendations. Efficient issue resolution involves prompt response times, effective troubleshooting, and a focus on first-contact resolution to minimize customer effort and ensure a seamless support experience. Additionally, continuous training for the support team and agents, leveraging automation tools, and collecting and analyzing customer feedback are essential components to continually improve and refine the SaaS support strategy."
                />
                <Faq
                  id="faq6"
                  question="How to build close relationship between support and product teams?"
                  answer="Building a close relationship between support and product teams involves fostering open communication, collaboration, and mutual understanding of roles and objectives. Regular meetings, cross-team training sessions, and shared documentation can facilitate knowledge sharing and alignment of goals. Additionally, creating channels for feedback exchange, such as dedicated Slack channels or internal forums, allows both product and engineering teams to provide input, address issues, and collaborate on product improvements. By cultivating a culture of teamwork and transparency, support and product teams can work together effectively to deliver exceptional customer experiences and drive product success."
                />
                <Faq
                  id="faq7"
                  question="How can customer support teams align with customer success?"
                  answer="Customer support teams can align with customer success by focusing on proactive problem-solving, continuous communication, customer service efforts, and a customer-centric approach. By understanding customers' goals, challenges, and expectations, support teams can anticipate needs and provide tailored assistance to help customers achieve success with the product or service. Additionally, collaborating closely with customer success teams to share insights, feedback, and best practices ensures alignment in delivering exceptional customer experiences and driving long-term value for customers. By working together towards common goals and prioritizing customer satisfaction and success, support teams can effectively align with customer success initiatives."
                />
                <Faq
                  id="faq8"
                  question="How to focus on proactive customer retention?"
                  answer="Focusing on proactive customer retention involves identifying and addressing potential issues before they escalate, building strong relationships with customers, and consistently delivering value. By implementing strategies such as regular check-ins, personalized communications, and proactive support outreach, businesses can demonstrate their commitment to customer success and foster loyalty. Additionally, leveraging data analytics to anticipate customer needs and preferences enables proactive problem-solving and targeted offers or recommendations. Ultimately, proactive customer retention efforts prioritize building long-term relationships retaining customers, and ensuring ongoing satisfaction, rather than simply reacting to issues as they arise."
                />
              </div>
              </div>
              </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
